<template>
  <div style="display: flex; flex-direction: column; padding: 4px">
    <div v-if="isGarage">
      <div class="info-div">
        <p class="info-title">Company</p>
        <p>{{ stop.parentCompanyName }}</p>
      </div>
      <div class="info-div">
        <p class="info-title">Garage</p>
        <p>{{ stop.garageName }}</p>
      </div>
    </div>
    <div>
      <div v-if="stopLabel">
        <div>{{ stopLabel }}</div>
      </div>
      <div v-else-if="stop.pickupDatetimeList || stop.dropoffDatetimeList">
        <div class="info-div">
          <p v-if="stop.pickupDatetimeList.length" class="info-title">
            Pickup Times:
          </p>
          <p v-for="(pickupDatetime, i) in stop.pickupDatetimeList" :key="i">
            {{ isoToString(pickupDatetime) }}
          </p>
        </div>
        <div class="info-div">
          <p v-if="stop.dropoffDatetimeList.length" class="info-title">
            Dropoff Times:
          </p>
          <p v-for="(dropoffDatetime, i) in stop.dropoffDatetimeList" :key="i">
            {{ isoToString(dropoffDatetime) }}
          </p>
        </div>
      </div>
    </div>
    <div class="info-div">
      <p class="info-title">Address</p>
      <p>{{ addressLabel }}</p>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon'

export default {
  props: {
    reservationId: { type: Number, default: null },
    stop: { type: Object, required: true },
    isGarage: { type: Boolean, default: false },
    timezone: { type: String, default: 'America/New_York' },
  },
  computed: {
    stopLabel() {
      const { pickupDatetime, dropOffDateTime } = this.stop
      let pickupLabel = ''
      let dropoffLabel = ''

      if (this.stop?.pickupDatetimeList && this.stop?.dropoffDatetimeList) {
        return null
      }

      if (pickupDatetime) {
        const date = new Date(pickupDatetime)
        pickupLabel = `Pickup: ${date.toDateString()} ${date.toLocaleTimeString()}`
      }
      if (dropOffDateTime) {
        const date = new Date(dropOffDateTime)
        dropoffLabel = `Dropoff: ${date.toDateString()} ${date.toLocaleTimeString()}`
      }
      if (pickupLabel.length && dropoffLabel.length) {
        return `${dropoffLabel}<br/>${pickupLabel}`
      }

      return pickupLabel.length ? pickupLabel : dropoffLabel
    },
    addressLabel() {
      const { address } = this.stop
      const {
        addressName,
        street1 = '',
        city = '',
        state = '',
        postal = '',
      } = address

      return addressName || `${street1} ${city} ${state}, ${postal}`
    },
  },
  methods: {
    isoToString(time) {
      const datetime = DateTime.fromISO(time, { zone: this.timezone })
      return datetime.toFormat('M/dd • t ZZZZ')
    }
  },
}
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}
.info-div {
  padding-bottom: 4px;
}
.info-title {
  color: $gray-medium-light;
}
</style>
