<template>
  <CRSidebarDialog
    v-model="active"
    persistent
    full-screen
    @input="closeHandlerWrapper"
  >
    <template #title>
      Tracking
      <a v-if="linkToReservationDetail" class="managed-id-link" target="_blank" :href="$router.resolve({ name: 'reservation-detail', params: { id: reservationId } }).href"
      >
        {{ managedId }}
      </a>
      <span v-else>{{ managedId }}</span>
    </template>
    <template #chip>
      <v-chip
        v-if="trackingAllocation && trackingReservationStatus"
        small
        label
        :class="`tracking-chip__${trackingReservationStatus.toLowerCase()}_${trackingAllocation.toLowerCase()}`"
      >
        <span>
          {{ trackingTags[trackingReservationStatus][trackingAllocation] }}
        </span>
      </v-chip>
    </template>
    <div class="map-created-by-sidebar w-full">
      <div class="map-created-by-sidebar--content">
        <ReservationTrackingMapNew
          :show-legend="true"
          :reservation-status="reservationStatus"
          :reservation-id="reservationId"
          :journeys="journeys"
          :start-date="startDate"
          :stops="stops"
          :trip-vehicle-groups="tripVehicleGroups"
          :referred-providers="referredProviders"
          :full-trip-vehicle-groups="fullTripVehicleGroups"
          :itinerary-items="itineraryItems"
          :trip-assignments="tripAssignments"
        />
      </div>
    </div>
  </CRSidebarDialog>
</template>

<script>
import { authComputed } from '@/state/helpers'
import ReservationTrackingMapNew from '@/components/ReservationTrackingMapNew.vue'

export default {
  components: {
    ReservationTrackingMapNew,
  },
  provide: {
    isInReservationMapSidebar: true,
  },
  props: {
    active: { type: Boolean, default: false },
    reservationId: { type: Number, default: () => null },
    managedId: { type: String, default: '' },
    reservationStatus: { type: String, default: '' },
    journeys: { type: Array, default: () => [] },
    startDate: { type: String, default: null },
    stops: { type: Array, default: () => [] },
    tripVehicleGroups: { type: Array, default: () => [] },
    fullTripVehicleGroups: { type: Array, default: () => [] },
    referredProviders: { type: Array, default: () => [] },
    itineraryItems: { type: Array, default: () => [] },
    trackingAllocation: { type: String, default: '' },
    trackingReservationStatus: { type: String, default: '' },
    tripAssignments: { type: Array, default: () => [] },
    closeHandler: { type: Function, default: () => {} },
    linkToReservationDetail: { type: Boolean, default: false },
  },
  data() {
    return {
      trackingTags: {
        UPCOMING: {
          NONE: 'Ineligible to Track',
          PARTIAL: 'Partially Eligible to Track',
          ALL: 'Eligible to Track',
        },
        STARTED: {
          NONE: 'Not Tracking',
          PARTIAL: 'Partially Tracking',
          ALL: 'Tracking',
        },
        FINISHED: {
          NONE: 'Not Tracked-On-Time',
          PARTIAL: 'Partially Tracked-On-Time',
          ALL: 'Tracked-On-Time',
        },
      },
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    closeDialog() {
      this.$store.dispatch('app/closeDialog')
    },
    closeHandlerWrapper() {
      this.closeHandler()
      this.closeDialog()
    },
  },
}
</script>
<style lang="scss" scoped>
.managed-id-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.tracking-chip {
  &__upcoming_all {
    background-color: $blue-new !important;
    color: $white;
  }
  &__started_all,
  &__finished_all {
    background-color: $success-new !important;
    color: $white;
  }

  &__upcoming_partial,
  &__started_partial,
  &__finished_partial {
    background-color: $yellow-new !important;
    color: $gray-dark;
  }

  &__upcoming_none,
  &__started_none,
  &__finished_none {
    background-color: $error-new !important;
    color: $white;
  }
}

.map-created-by-sidebar {
  height: 100%;
  overflow-y: hidden;

  &--content {
    height: 100%;
    flex: 1;
  }
}

.legend-btn {
  margin-top: 10px;
  margin-left: 10px;
  color: $white;
  background-color: $blue;
}
</style>
